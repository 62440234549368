import 'react-app-polyfill/ie9'; // Provides Map, which Opera 12.16 lacks
import 'react-app-polyfill/stable';

// import 'react-app-polyfill/ie11';



import logo from './logo.svg';
import './App.css';
import axios from 'axios';

var mailStyle={
  color: 'red'
}

function App() {
  sendMail();
  
  return (
    <div className="App">
      <header className="App-header">
        <h1>Fakturomat.pl</h1>
        <hr/>
        <p>Dzień dobry!<br/></p>
        <p>Wkrótce znajdzie się tu opis Krajowego Systemu e-faktur - KSeF.</p>
        <p>Fakturomat.pl umożliwi Państwu wystawianie odbieranie elektronicznych faktur z KSeF</p>
        <p>Prosimy odwiedźcie nas niedługo :)</p>

        <br/><hr/>
        <p>Kontakt: <a style={mailStyle} href="mailto:fakturomat.pl@gmail.com">fakturomat.pl@gmail.com</a></p>
      </header>
    </div>
  );
}

function sendMail(){
  console.log('sendMail');

  axios.post('https://fakturomat.pl:3880/api/sendmail',             {
            headers:{
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
    .then(response=>console.log(response.data));
}

export default App;
